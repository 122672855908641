import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de corall de fins a 5-7 cm d’altura. Les branques són rodones o un poc aplanades, ramificades, de color groc o un poc olivaci. Els extrems acaben en 2 o 4 puntes de color més clar que les branques. Les espores són rodones o el·líptiques un poc allargades, cobertes de berrugues, de 9-10,5 x 3,5-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      